import ApiService from './api.service';

const serviceURL = 'bank';

const autoBankService = {
	/**
	 * Get BankName
	 * @param {String} letters Japanese letters
	 * @returns Bank object
	 */
	getBankName(letters) {
		if (!letters) {
			console.log('no bankName letters');
			return [undefined, undefined];
		}

		return ApiService.get(`${serviceURL}?name=${letters}`);
	},

	/**
	 * Get BankName
	 * @param {String} letters Japanese letters
	 * @returns Bank object
	 */
	getBranchName(letters, bankCode) {
		if (!bankCode) {
			console.log('no bankCode');
			return [undefined, undefined];
		}

		return ApiService.get(`${serviceURL}/${bankCode}/branch?name=${letters}`);
	},
};

export default autoBankService;
