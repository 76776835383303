import AutoBankService from '@/services/autoBank.service';

export default {
	data() {
		return {
			bankCode: undefined,
			autoBanks: [],
			autoBranches: [],
			// debounce Bank
			timerBank: 300,
			timeOutBank: null,
			counterBank: 0,

			// debounce Branch
			timerBranch: 300,
			timeOutBranch: null,
			counterBranch: 0,
		};
	},
	watch: {
		async 'model.bankName'(newBankName, oldBankName) {
			console.log({ newBankName, oldBankName });
			if (oldBankName === undefined) return;

			this.counterBank += 1;
			clearTimeout(this.timeOutBank);

			this.timeOutBank = setTimeout(async () => {
				await this.getBankName(newBankName);
			}, this.timerBank);
		},

		async 'model.bankBranchName'(newbranchName, oldBranchName) {
			console.log({ newbranchName, oldBranchName });
			if (oldBranchName === undefined) return;

			this.counterBranch += 1;
			clearTimeout(this.timeOutBranch);

			this.timeOutBranch = setTimeout(async () => {
				await this.getBranchName(newbranchName, this.bankCode);
			}, this.timerBranch);
		},
	},
	methods: {
		onSelectOption(item) {
			console.log({ item });
			if (item?.bank_code) {
				this.model.bankBranchName = item?.name_kanji;
			} else {
				this.model.bankBranchName = this.model.bankBranchName || '';

				this.bankCode = item?.code;
				this.model.bankName = item?.name_kanji;
			}
		},

		async onBranchEmpty(value) {
			await this.getBranchName(value, this.bankCode);
			this.model.bankName = value;
		},

		async onBankEmpty(value) {
			this.model.bankBranchName = value;
		},

		/**
		 * Get bankName by letters
		 * @param {String} letters letters of bankName
		 * @returns {Array} list of bankName objects
		 */
		async getBankName(letters) {
			try {
				const [response, error] = await AutoBankService.getBankName(letters);

				if (!response) {
					throw Error('Get bankName error');
				}

				const banks = response;
				console.log({ banks });

				this.autoBanks = banks ? banks : [];
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Get branchName by letters
		 * @param {String} letters letters of branchName
		 * @returns {Array} list of branchName objects
		 */
		async getBranchName(letters, bankCode) {
			console.log("getbranchname called",{ letters, bankCode });
			try {
				if (this.model.bankName == '') {
					this.autoBranches = [];
					return;
				}
				const [response, error] = await AutoBankService.getBranchName(
					letters,
					bankCode
				);

				if (!response) {
					throw Error('Get bankName error');
				}

				const branchs = response;
				console.log({ branchs });
				this.autoBranches = branchs ? branchs : [];
			} catch (error) {
				console.log(error);
			}
		},
	},
	mounted() {
		// setTimeout(async () => {
		// 	console.log("this.model.bankName", this.model.bankName);
		// 	if (!this.bankCode && this.model.bankName) {
		// 		const [response, error] = await AutoBankService.getBankName(this.model.bankName);

		// 		if (!response) {
		// 			throw Error('Get bankName error');
		// 		}

		// 		const banks = response;
		// 		this.onSelectOption(banks[0]);
		// 	}
		// })

		let interval = setInterval(async () => {
			if (this.model.bankName) {
				if (!this.bankCode && this.model.bankName) {
					const [response, error] = await AutoBankService.getBankName(this.model.bankName);
	
					if (!response) {
						throw Error('Get bankName error');
					}
	
					const banks = response;
					this.onSelectOption(banks[0]);
				}
				clearInterval(interval);
			}
		});
	}
};
